import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'

const GatsbyLogo = require('../../svgs/tools/gatsby.svg')
const LaravelLogo = require('../../svgs/tools/laravel.svg')
const ReactLogo = require('../../svgs/tools/react-logo.svg')
const VueLogo = require('../../svgs/tools/vue.svg')
const WordpressLogo = require('../../svgs/tools/wordpress.svg')

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "ferris-wheel.jpg" }) {
      ...heroImage
    }
  }
`
const AppDevelopmentPage = () => (
  <Layout>
    <SEO
      title="About Us | amillionmonkeys"
      description="We build websites, apps and other digital products; offering a simple, affordable solution to businesses and agencies that do not have in-house developers"
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="max-w-md m-auto">
              <p className="text-3xl text-white text-center  font-serif text-shadow">
                We build websites, apps and other digital products; offering a simple, affordable solution to businesses
                and agencies that do not have in-house developers.
              </p>
            </div>
          </Hero>
          <div className="bg-blue-darker bg-stamp text-300 text-white py-16  px-4  pb-24 ">
            <div className="max-w-lg m-auto text-2xl font-serif font-light text-center">
              <p className="mb-4">
                <strong>
                  Having worked with many different agencies across countless projects, we understand the nature of
                  agency life; we’re cooperative, easy to communicate with and understand the importance of budgets and
                  deadlines.
                </strong>
              </p>
              <p className="mb-4">
                We’ve been lucky enough to work with some of the best and most respected agencies and brands,
                <Link to="work" className="text-blue-lighter">
                  {' '}
                  take a look at our portfolio
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="bg-blue-lighter bg-stamp text-300 text-blue-darker py-16  px-4  pb-24 ">
            <div className="m-auto text-2xl font-serif font-light text-center">
              <h2 className="font-impact  text-4xl">Our Tools</h2>
              <p>While we're happy to give most things a go, these are the sharpest tools in our box:</p>
              <div className="mx-auto max-w-md">
                <ul className="list-reset brand-grid text-white">
                  <li>
                    <LaravelLogo className="w-full h-auto" />
                    <div className=" flex justify-center items-center">
                      <h2 className="font-impact">Laravel</h2>
                      <p>...for web applications and APIs</p>
                    </div>
                  </li>
                  <li>
                    <ReactLogo className="w-full h-auto" />
                    <div className=" flex justify-center items-center">
                      <h2 className="font-impact">React</h2>
                      <p>...for slick UI on both both web (React) and mobile (React Native)</p>
                    </div>
                  </li>
                  <li>
                    <GatsbyLogo className="w-full h-auto" />
                    <div className=" flex justify-center items-center">
                      <h2 className="font-impact">Gatsby</h2>
                      <p>...for build blazing fast websites and apps</p>
                    </div>
                  </li>
                  <li>
                    <VueLogo className="w-full h-auto" />
                    <div className=" flex justify-center items-center">
                      <h2 className="font-impact">VueJS</h2>
                      <p>...for a little bit of sugar</p>
                    </div>
                  </li>
                  <li>
                    <WordpressLogo className="w-full h-auto" />
                    <div className=" flex justify-center items-center">
                      <h2 className="font-impact">Wordpress</h2>
                      <p>...for a familiar back-end</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default AppDevelopmentPage
